import React, { Fragment, useContext } from "react";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { Divider, Grid, GridItem, Heading, HStack, Link, Text } from "@chakra-ui/react";
import { BoxData } from "components";
import { currency } from "lib";
import MeetingsDetailsContext from "../context";

const TravelCost = () => {
  const { formData } = useContext(MeetingsDetailsContext);

  return (
    <Fragment>
      <Divider my={6} />
      <Heading size="sm" mb={4}>
        Custo de viagem
      </Heading>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <BoxData label="Custo de viagem" value={currency.format(formData.travelCostAmount)} />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <BoxData label="Pago por" value={formData.travelCostPaidBy?.name || "-"} />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <BoxData
            label="Contas geradas"
            value={
              <HStack divider={<Text pr="4px">,</Text>}>
                {_.map(formData.bills, (bill) => (
                  <Link as={RouterLink} to={`/bills/${bill.type}/edit/${bill._id}`} target="_blank">
                    #{bill.nid}
                  </Link>
                ))}
              </HStack>
            }
          />
        </GridItem>
      </Grid>
    </Fragment>
  );
};

export default TravelCost;
