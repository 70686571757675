import moment from "moment";
import { HStack, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { formatBytes } from "lib";
import { CustomerLogo } from "components";

const defaultColumns = [
  {
    accessor: "title",
    title: "Título",
    isVisible: true,
    formatter: ({ title }) => title,
  },
  {
    accessor: "ext",
    title: "Extensão",
    isVisible: true,
    formatter: ({ ext }) => ext,
  },
  {
    accessor: "size",
    title: "Tamanho",
    isVisible: true,
    formatter: ({ size }) => formatBytes(size),
  },
  {
    accessor: "customer",
    title: "Cliente",
    isVisible: true,
    isSortable: false,
    render: ({ ownerRef, owner }) =>
      ownerRef === "Customer" && owner ? (
        <HStack>
          <CustomerLogo boxSize="30px" alt={owner?.tradingName} src={owner?.logoUrl} />
          <Link as={RouterLink} to={`/customers/edit/${owner?._id}`} target="_blank">
            {owner?.tradingName || "-"}
          </Link>
        </HStack>
      ) : (
        "-"
      ),
    exporter: ({ ownerRef, owner }) => (ownerRef === "Customer" ? owner?.tradingName : "-"),
  },
  {
    accessor: "meeting",
    title: "Reunião",
    isVisible: true,
    isSortable: false,
    render: ({ ownerRef, owner }) =>
      ownerRef === "Meeting" && owner ? (
        <Link as={RouterLink} to={`/meetings/edit/${owner?._id}`} target="_blank">
          #{owner?.nid}
        </Link>
      ) : (
        "-"
      ),
    exporter: ({ ownerRef, owner }) => (ownerRef === "Meeting" ? owner?.nid : "-"),
  },
  {
    accessor: "task",
    title: "Tarefa",
    isVisible: true,
    isSortable: false,
    render: ({ ownerRef, owner }) =>
      ownerRef === "Task" && owner ? (
        <Link as={RouterLink} to={`/tasks/edit/${owner?._id}`} target="_blank">
          #{owner?.nid}
        </Link>
      ) : (
        "-"
      ),
    exporter: ({ ownerRef, owner }) => (ownerRef === "Task" ? owner?.nid : "-"),
  },
  {
    accessor: "createdBy",
    title: "Criada por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
