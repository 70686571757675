import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  SlideFade,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import * as yup from "yup";
import { messages } from "consts";
import { api } from "lib";
import { PermissionedContainer, Breadcrumb, BoxData, DocumentHistory, SyncSelect, InputCurrency } from "components";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { MdChevronLeft, MdHistory, MdRefresh } from "react-icons/md";
import { Content } from "pages/Private/Container";

export const DemandsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar demanda" : "Nova demanda");
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/demands/${_id}` }), [_id]));
  const [teams, isLoadingTeams, refreshTeams] = useApiGet(
    useMemo(
      () => ({
        path: "/teams",
        params: {
          query: { isActive: true },
          sort: { title: 1 },
          perPage: -1,
          isAutocomplete: true,
        },
      }),
      []
    )
  );
  const users = useMemo(() => _.find(teams?.data, (o) => o._id === formData.team?._id)?.users ?? [], [formData.team?._id, teams?.data]);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? { isActive: true };
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/demands/${_id}`, data) : await api.put("/demands", data);
        navigate(`/settings/records/demands/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        team: yup.string().required(messages.error.required),
        responsible: yup.string().required(messages.error.required),
        businessDaysForDueDate: yup.number().required(messages.error.required),
      });
      const data = {
        ...formData,
        team: formData.team?._id,
        responsible: formData.responsible?._id,
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/settings#records", label: "cadastros" },
                { to: "/settings/records/demands", label: "demandas" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Demanda</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
          <GridItem colSpan={{ base: 4, lg: 2 }}>
            <BoxData label="NID" value={formData.nid?.toLocaleString() ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 8, lg: 10 }}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel fontSize="sm">Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isRequired={true} isInvalid={formErrors.team}>
              <HStack mb="0.5em" justifyContent="space-between">
                <FormLabel fontSize="sm" m="0">
                  Time
                </FormLabel>
                {formData.team ? (
                  <PermissionedContainer required="teams:update">
                    <Link as={RouterLink} to={`/settings/records/teams/edit/${formData.team._id}`} target="_blank" fontSize="xs">
                      editar cadastro
                    </Link>
                  </PermissionedContainer>
                ) : (
                  <PermissionedContainer required="teams:create">
                    <Link as={RouterLink} to={`/settings/records/teams/new`} target="_blank" color="blue.500" fontSize="xs">
                      incluir cadastro
                    </Link>
                  </PermissionedContainer>
                )}
              </HStack>
              <HStack>
                <SyncSelect
                  value={formData.team}
                  options={teams?.data}
                  placeholder="Selecione"
                  onChange={(team) => setFormData((state) => ({ ...state, team, responsible: null }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
                <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingTeams} onClick={refreshTeams} />
              </HStack>
              <FormErrorMessage>{formErrors.team}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isRequired={true} isInvalid={formErrors.responsible}>
              <HStack mb="0.5em" justifyContent="space-between">
                <FormLabel fontSize="sm" m="0">
                  Responsável
                </FormLabel>
                {formData.responsible ? (
                  <PermissionedContainer required="users:update">
                    <Link as={RouterLink} to={`/users/edit/${formData.responsible._id}`} target="_blank" fontSize="xs">
                      editar cadastro
                    </Link>
                  </PermissionedContainer>
                ) : (
                  <PermissionedContainer required="users:create">
                    <Link as={RouterLink} to={`/users/new`} target="_blank" color="blue.500" fontSize="xs">
                      incluir cadastro
                    </Link>
                  </PermissionedContainer>
                )}
              </HStack>
              <SyncSelect
                value={formData.responsible}
                options={users}
                placeholder="Selecione"
                onChange={(responsible) => setFormData((state) => ({ ...state, responsible }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ avatarUrl, name }) => (
                  <HStack>
                    <Avatar size="xs" name={name} src={avatarUrl} />
                    <Text>{name}</Text>
                  </HStack>
                )}
                isClearable={true}
                isDisabled={!formData.team}
              />
              <FormErrorMessage>{formErrors.responsible}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <FormControl isRequired={true} isInvalid={formErrors.informedAvgDurationInMinutes}>
              <FormLabel fontSize="sm">Duração média informada</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.informedAvgDurationInMinutes ?? ""}
                  onChange={(informedAvgDurationInMinutes) => setFormData((state) => ({ ...state, informedAvgDurationInMinutes }))}
                />
                <InputRightAddon fontSize="sm">minutos</InputRightAddon>
              </InputGroup>
              <FormErrorMessage>{formErrors.informedAvgDurationInMinutes}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <BoxData
              label="Duração média calculada"
              value={`${formData.calculatedAvgDurationInMinutes?.toLocaleString() ?? "-"} minutos`}
            />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <FormControl isRequired={true} isInvalid={formErrors.businessDaysForDueDate}>
              <FormLabel fontSize="sm">Dias úteis para vencimento</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.businessDaysForDueDate ?? ""}
                  onChange={(businessDaysForDueDate) => setFormData((state) => ({ ...state, businessDaysForDueDate }))}
                  allowNegative={true}
                />
                <InputRightAddon fontSize="sm">dias</InputRightAddon>
              </InputGroup>
              <FormErrorMessage>{formErrors.businessDaysForDueDate}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Content>

      <PermissionedContainer required={_id ? "demands:update" : "demands:create"}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && <DocumentHistory path={`/demands/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}
    </>
  );
};
