import moment from "moment";
import _ from "lodash";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { currency, locale, percent, translator, translatorBills } from "lib";
import { CustomBadge } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "dueDate",
    title: "Vencimento",
    isVisible: true,
    formatter: ({ dueDate }) => (dueDate ? moment(dueDate).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "category",
    title: "Categoria",
    isVisible: true,
    formatter: ({ category }) => translator(category),
  },
  {
    accessor: "description",
    title: "Descrição",
    isVisible: true,
    render: ({ _id, description }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {description}
      </Link>
    ),
    exporter: ({ description }) => description,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => (status ? <CustomBadge.Bills.Status status={status} /> : "-"),
    exporter: ({ status }) => translatorBills(status),
  },
  {
    accessor: "bankAccount",
    title: "Conta bancária",
    isVisible: true,
    formatter: ({ bankAccount }) => bankAccount?.name || "-",
  },
  {
    accessor: "amount",
    title: "Valor",
    isVisible: true,
    render: ({ amount }) => currency.format(amount),
    exporter: ({ amount }) => locale(amount),
  },
  {
    accessor: "allocation.costCenter",
    title: "Centro de custo",
    isVisible: true,
    isSortable: false,
    render: ({ allocation }) => _.map(allocation, "costCenter.title").join(", "),
    exporter: ({ allocation }) => allocation?.costCenter?.title ?? "-",
  },
  {
    accessor: "allocation.chartOfAccount.name",
    title: "Plano de contas",
    isVisible: true,
    isSortable: false,
    render: ({ allocation }) => _.map(allocation, "chartOfAccount.name").join(", "),
    exporter: ({ allocation }) => allocation?.chartOfAccount?.name ?? "-",
  },
  {
    accessor: "allocation.chartOfAccount.code",
    title: "Cód. plano de contas",
    isVisible: true,
    isSortable: false,
    render: ({ allocation }) => _.map(allocation, "chartOfAccount.code").join(", "),
    exporter: ({ allocation }) => allocation?.chartOfAccount?.code ?? "-",
  },
  {
    accessor: "allocation.company",
    title: "Empresa",
    isVisible: true,
    isSortable: false,
    render: ({ allocation }) => _.map(allocation, "company.tradingName").join(", "),
    exporter: ({ allocation }) => allocation?.company?.tradingName ?? "-",
  },
  {
    accessor: "allocation.percentage",
    title: "Porcentagem rateio",
    isVisible: true,
    isSortable: false,
    render: ({ allocation }) => _.map(allocation, (o) => percent.format(o.percentage || 0)).join(", "),
    exporter: ({ allocation }) => percent.format(allocation?.percentage || 0),
  },
  {
    accessor: "entity",
    title: "Entidade",
    isVisible: true,
    formatter: ({ entity }) => entity?.tradingName || entity?.name || "-",
  },
  {
    accessor: "referenceDate",
    title: "Data de referência",
    isVisible: true,
    formatter: ({ referenceDate }) => (referenceDate ? moment(referenceDate).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "paymentMethod",
    title: "Método de pagamento",
    isVisible: true,
    formatter: ({ paymentMethod }) => translator(paymentMethod),
  },
  {
    accessor: "paidAt",
    title: "Pago em",
    isVisible: true,
    formatter: ({ paidAt }) => (paidAt ? moment(paidAt).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "finishedAt",
    title: "Finalizada em",
    isVisible: true,
    formatter: ({ finishedAt }) => (finishedAt ? moment(finishedAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "finishedBy",
    title: "Finalizada por",
    isVisible: true,
    formatter: ({ finishedBy }) => finishedBy?.name ?? "-",
  },
  {
    accessor: "source.nid",
    title: "Reunião",
    isVisible: true,
    isSortable: false,
    render: ({ source }) =>
      source ? (
        <Link as={RouterLink} to={`/meetings/edit/${source._id}`} target="_blank">
          #{source.nid}
        </Link>
      ) : (
        "-"
      ),
    exporter: ({ source }) => source?.nid ?? "-",
  },
  {
    accessor: "source.travelCostPaidBy",
    title: "Consultor",
    isVisible: true,
    isSortable: false,
    formatter: ({ source }) => source?.travelCostPaidBy?.name ?? "-",
  },
  {
    accessor: "createdBy",
    title: "Criada por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
