import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
  useToken,
  VStack,
} from "@chakra-ui/react";
import { PermissionedContainer } from "components";
import { PrivateContext } from "pages/Private";
import { MdAddChart, MdClose } from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import { IoHelpBuoyOutline } from "react-icons/io5";
import { ContainerContext } from "./index";
import useCounters from "./useCounters";
import HomeIcon from "assets/icons/home";
import MeetingsIcon from "assets/icons/meetings";
import CustomersIcon from "assets/icons/customers";
import CustomerIndicatosIcon from "assets/icons/customer-indicators";
import TasksIcon from "assets/icons/tasks";
import SolicitationsIcon from "assets/icons/solicitations";
import UsersIcon from "assets/icons/users";
import { PiHandArrowUp, PiHandArrowDown, PiStudentBold } from "react-icons/pi";
import { PiPresentationChartBold } from "react-icons/pi";
import { LuTruck } from "react-icons/lu";
import { FaRegFileArchive } from "react-icons/fa";
import { useClipboard, useCustomToast } from "hooks";
import { api } from "lib";

const MenuItem = memo(({ href, icon, title, RightComponent, onClick, ...props }) => {
  const [backgroundColor, color] = useToken("colors", [
    useColorModeValue("main.50", "whiteAlpha.100"),
    useColorModeValue("main.500", "white"),
  ]);

  const handleActiveStyle = useCallback(
    ({ isActive }) => {
      if (isActive) return { backgroundColor, color };
      return {};
    },
    [backgroundColor, color]
  );

  return (
    <HStack
      py="5px"
      px="10px"
      cursor="pointer"
      transition="200ms"
      borderRadius="md"
      _hover={{ bg: "blackAlpha.100" }}
      as={href ? "a" : NavLink}
      style={href ? {} : handleActiveStyle}
      onClick={onClick}
      {...props}
    >
      {icon}
      <Text fontSize="xs" fontWeight="500" flex="1">
        {title}
      </Text>
      {RightComponent}
    </HStack>
  );
});

const CountBadge = ({ colorScheme, label, count }) => {
  return (
    count > 0 && (
      <Tooltip label={`${count.toLocaleString()} ${label}`} placement="right">
        <Badge variant="solid" colorScheme={colorScheme} borderRadius="full" fontSize="x-small">
          {count}
        </Badge>
      </Tooltip>
    )
  );
};

const MenuList = () => {
  const { currentUser } = useContext(PrivateContext);
  const [isLoadingFacultySsoToken, setIsLoadingFacultySsoToken] = useState(false);
  const [facultyClickTimeout, setFacultyClickTimeout] = useState();
  const counters = useCounters();
  const toast = useCustomToast();
  const copyToClipboard = useClipboard();

  const handleFacultySsoToken = useCallback(async () => {
    try {
      setIsLoadingFacultySsoToken(true);
      const data = await api.post(`/auth/sso/generate/${currentUser._id}`);
      return data.ssoToken;
    } catch (error) {
      if (error.isHandled) return;
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingFacultySsoToken(false);
    }
  }, [currentUser._id]);

  const handleFacultyClick = useCallback(() => {
    if (facultyClickTimeout) return;
    const timeout = setTimeout(async () => {
      setFacultyClickTimeout(null);
      const ssoToken = await handleFacultySsoToken();
      if (ssoToken) window.open(`http://faculdade.formatar.com.br/auth/sso?token=${ssoToken}`, "_blank");
    }, 250);
    setFacultyClickTimeout(timeout);
  }, [facultyClickTimeout, handleFacultySsoToken]);

  const handleFacultyDoubleClick = useCallback(async () => {
    if (facultyClickTimeout) {
      clearTimeout(facultyClickTimeout);
      setFacultyClickTimeout(null);
      const ssoToken = await handleFacultySsoToken();
      if (ssoToken) copyToClipboard(ssoToken);
    }
  }, [facultyClickTimeout, copyToClipboard, handleFacultySsoToken]);

  return (
    <Box minW="220px" overflowY="auto">
      <VStack alignItems="stretch" spacing="0" py="5px" px="10px">
        <Text fontWeight="600" fontSize="sm" py="5px" px="8px">
          Índice
        </Text>
        <MenuItem icon={<HomeIcon />} to="/home" title="Página inicial" />
        <PermissionedContainer required={["dashboards:activities", "dashboards:scheduling"]}>
          <MenuItem icon={<Icon as={MdAddChart} color="#cc7729" />} to="/dashboards" title="Gestão à Vista" />
        </PermissionedContainer>
      </VStack>

      <VStack alignItems="stretch" spacing="0" py="5px" px="10px">
        <Text fontWeight="600" fontSize="sm" py="5px" px="8px">
          Cadastros
        </Text>
        <MenuItem
          icon={<MeetingsIcon />}
          to="/meetings/grid"
          title="Reuniões"
          RightComponent={
            <HStack spacing="2px">
              <CountBadge colorScheme="gray" label="previstos" count={counters.unscheduledMeetings} />
              <CountBadge colorScheme="yellow" label="agendados" count={counters.scheduledMeetings} />
              <CountBadge colorScheme="red" label="atrasados" count={counters.delayedMeetings} />
            </HStack>
          }
        />
        <PermissionedContainer required="customers:read">
          <MenuItem
            icon={<CustomersIcon />}
            to="/customers"
            title="Clientes"
            RightComponent={<CountBadge colorScheme="red" label="desvios de horas contratadas" count={counters.customers} />}
          />
        </PermissionedContainer>
        <PermissionedContainer required="suppliers:read">
          <MenuItem icon={<Icon as={LuTruck} color="#cc7729" />} to="/suppliers" title="Fornecedores" />
        </PermissionedContainer>
        <PermissionedContainer required="customer-indicators:read">
          <MenuItem icon={<CustomerIndicatosIcon />} to="/customer-indicators" title="Indicadores" />
        </PermissionedContainer>
        <MenuItem
          icon={<TasksIcon />}
          to="/tasks/grid"
          title="Tarefas"
          RightComponent={
            <HStack spacing="2px">
              <CountBadge colorScheme="gray" label="previstos" count={counters.unscheduledTasks} />
              <CountBadge colorScheme="yellow" label="pendentes" count={counters.pendingTasks} />
              <CountBadge colorScheme="red" label="atrasados" count={counters.delayedTasks} />
            </HStack>
          }
        />
        <MenuItem
          icon={<SolicitationsIcon />}
          to="/solicitations"
          title="Solicitações"
          RightComponent={<CountBadge label="pendentes" colorScheme="yellow" count={counters.solicitations} />}
        />
        <PermissionedContainer required="users:read">
          <MenuItem icon={<UsersIcon />} to="/users" title="Usuários" />
        </PermissionedContainer>
        <PermissionedContainer required="consultations:read">
          <MenuItem icon={<Icon as={PiPresentationChartBold} color="#cc7729" />} to="/consultations" title="Consultorias" />
        </PermissionedContainer>
        <PermissionedContainer required="files:read">
          <MenuItem icon={<Icon as={FaRegFileArchive} color="#cc7729" />} to="/files" title="Arquivos" />
        </PermissionedContainer>
        <PermissionedContainer required="faculty:read">
          <MenuItem
            href="#"
            icon={<Icon as={PiStudentBold} color="#cc7729" />}
            title="Faculdade Formatar"
            RightComponent={isLoadingFacultySsoToken && <Spinner size="xs" />}
            onClick={handleFacultyClick}
            onDoubleClick={handleFacultyDoubleClick}
          />
        </PermissionedContainer>
      </VStack>

      <PermissionedContainer required={["bills:payable:read", "bills:receivable:read"]}>
        <VStack alignItems="stretch" spacing="0" py="5px" px="10px">
          <Text fontWeight="600" fontSize="sm" py="5px" px="8px">
            Financeiro
          </Text>
          <PermissionedContainer required="bills:payable:read">
            <MenuItem
              icon={<Icon as={PiHandArrowUp} color="#cc7729" boxSize="22px" />}
              to="/bills/payable"
              title="Pagamentos"
              RightComponent={<CountBadge colorScheme="red" label="atrasados" count={counters.payableBills} />}
            />
          </PermissionedContainer>
          <PermissionedContainer required="bills:receivable:read">
            <MenuItem
              icon={<Icon as={PiHandArrowDown} color="#cc7729" boxSize="22px" />}
              to="/bills/receivable"
              title="Recebimentos"
              RightComponent={<CountBadge colorScheme="red" label="atrasados" count={counters.receivableBills} />}
            />
          </PermissionedContainer>
        </VStack>
      </PermissionedContainer>

      <VStack alignItems="stretch" spacing="0" py="5px" px="10px">
        <PermissionedContainer
          required={[
            "teams:read",
            "demands:read",
            "meeting-rooms:read",
            "meeting-types:read",
            "indicator-types:read",
            "user-groups:read",
            "segments:read",
          ]}
        >
          <MenuItem to="/settings" title="Configurações" icon={<Icon as={RiSettings4Line} />} />
        </PermissionedContainer>
        <MenuItem
          href={`https://wa.me/5537991581502?text=Boa tarde! Preciso de suporte. Empresa Formatar Consultoria. Usuário ${currentUser._id} - ${currentUser.name}.`}
          target="_blank"
          title="Ajuda"
          icon={<Icon as={IoHelpBuoyOutline} />}
        />
      </VStack>
    </Box>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const { isMobileView, isOpenSidebar, onCloseSidebar } = useContext(ContainerContext);

  useEffect(() => {
    onCloseSidebar();
  }, [location]);

  return isMobileView ? (
    <Drawer isOpen={isOpenSidebar} placement="left" onClose={onCloseSidebar}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <IconButton variant="outline" isRound icon={<Icon as={MdClose} />} onClick={onCloseSidebar} />
        </DrawerHeader>
        <DrawerBody p="0">
          <MenuList />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  ) : (
    <MenuList />
  );
};

export default Sidebar;
